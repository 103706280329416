import React from "react";
import {FaWallet , FaCamera , FaSocks} from "react-icons/fa"

const tips = 
[
    {
        icon: <FaWallet /> ,
        title: "saving money ",
        text : "Travel by train"
    },
    {
        icon: <FaCamera /> ,
        title : "top attractions ",
        text : " top attraction",
    },
    {
        icon: <FaSocks/> ,
        title : "amazing comfort",
        text : "stay in Oyo rooms",
    },
]
export default tips
