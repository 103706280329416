import React from "react";
import Title from "../Title"
import * as styles from "../../css/about.module.css"
//import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

// ok so now this should work
const getAbout = graphql`
    {
      aboutImage: file(relativePath: {eq: "defaultBcg.jpeg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `;
  

const About = ()=> {
    // i already used the static query earlier
    const { aboutImage } = useStaticQuery(getAbout);
    return(
        <section className={styles.about}>
            <Title title = "about" subtitle="hampi"/>
            <div className={styles.aboutCenter}>
                <article className={styles.aboutImg}>
                    <div className={styles.imgContainer}>
                       {/* <img src={img} alt="about company"/> */}
                       <Img fluid={aboutImage.childImageSharp.fluid} alt="landscape" />
                    </div>
                </article>
                <article className={styles.aboutInfo}>
                    <h4>the abode of bygone ruins</h4>
                    <p> Hampi , the city of ruins</p>
                    <p>surrounded by 500 ancient monuments</p>
                    <a href="https://en.wikipedia.org/wiki/Hampi" className="btn-secondary" 
                                                                  target="_blank" 
                                                                  rel="noopener noreferrer"
                    >
                        read more
                    </a>
                </article>
            </div>
        </section>
    )

}

export default About