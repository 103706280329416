import React        from "react"
import Layout       from "../components/Layout"
import Banner       from "../components/Banner"
import { Link }     from "gatsby"
import About        from "../components/Home/About"
import Tips         from "../components/Home/Tips"
import StyledHero   from "../components/StyledHero"
import {graphql}    from "gatsby"
import FeaturedPlaces from "../components/Home/FeaturedPlaces"
import Seo            from "../components/SEO"


const index = ({ data }) =>
{ 
  return(
  <div>
    <Layout>
      <Seo title="Webitsu"/>
      <StyledHero home = "true" img ={data.defaultBcg.childImageSharp.fluid} >
        <Banner title="Great design" info="technological elegance">
          <Link to="/places" className="btn-white">
            explore places
          </Link>
        </Banner>
      </StyledHero>
      <About/>
      <Tips />
      <FeaturedPlaces/>
    </Layout>
  </div>
)}

export const query = graphql`
  {
    defaultBcg: file(relativePath: {eq: "defaultBcg.jpeg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default index